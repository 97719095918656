.button-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-import {
  margin: auto;
  width: 78vw;
  height: 45vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 20px;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.button-import:hover {
  background-color: #1f989e7d;
  box-shadow: 0px 15px 20px #a1e0df;
  color: #fff;
  transform: translateY(-7px);
}
